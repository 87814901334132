import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/designer/free-photoshop-plugins",
  "date": "2014-03-04",
  "title": "FREE PHOTOSHOP PLUGINS",
  "author": "admin",
  "tags": ["design", "photoshop"],
  "featuredImage": "feature.jpg",
  "excerpt": "There are so many amazing free Photoshop plugins you can download for Photoshop CS6 and CC. There are plugins for photo effects, noise reduction, painting, abstract art, layouts, and more! Let’s have a look at few of the best."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Flaticon`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-04-free-photoshop-plugins/flaticonplug.jpg",
        "alt": "Flaticon"
      }}></img></p>
    <p>{`This free plugin for Photoshop lets you quickly find all the icons without leaving your working environment. You can download free icons, Freepik has launched a tool entirely dedicated to this end: `}<a href="http://Flaticon.com" target="_blank" shape="rect">{`Flaticon.com`}</a>{`. The largest database of free vector icons, it enables you to download all of its thousands of icons in .svg, .psd or .png format.`}</p>
    <p><strong parentName="p">{`*Publisher:`}</strong>{` `}<a parentName="p" {...{
        "href": "http://www.freepik.com"
      }}>{`freepik.com`}</a>{`
`}<strong parentName="p">{`*Good for:`}</strong>{` Web designers`}</p>
    <h2>{`CSS3PS`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-04-free-photoshop-plugins/02.jpg",
        "alt": "CSS3PS"
      }}></img></p>
    <p>{`This free plugin makes it easy to convert your Photoshop layers into CSS3 layers Uses the cloud to perform the calculations required to convert your Photoshop layers into CSS3 layers, complete with live-rendered effects where these are achievable with CSS. So, drop-shadows, strokes, outer glows, text and rounded corners are all converted to CSS3 automatically.`}</p>
    <p><strong parentName="p">{`*Publisher:`}</strong>{` `}<a parentName="p" {...{
        "href": "http://css3ps.com/"
      }}>{`CSS3PS`}</a>{`
`}<strong parentName="p">{`*Good for:`}</strong>{` Web designers`}</p>
    <h2>{`Perfect Effects 3 Free Edition`}</h2>
    <p>{`Perfect Effects 8 Free Edition gives you an easy way to add subtle to striking effects to your photos instantly or take complete creative control and transform your images into uniquely stylized images. It's also the easiest and most versatile way to add effects when you're using Adobe® Photoshop®, Photoshop Elements, Lightroom®, Apple® Aperture®, or Perfect Effects 8 Free by itself as a standalone application. Whether that's a color treatment, addition of texture and noise, or creative borders. The plugin features an effects library (a bit like the filter gallery in Photoshop) and allows you to stack multiple effects to achieve interesting new results.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-04-free-photoshop-plugins/w640.jpg",
        "alt": "Perfect Effects 3"
      }}></img></p>
    <p>{`If you want to get a quick effect on your image, try this plugin`}</p>
    <p><strong parentName="p">{`*Publisher:`}</strong>{` `}<a parentName="p" {...{
        "href": "http://www.ononesoftware.com/"
      }}>{`onOne Software`}</a>{`
`}<strong parentName="p">{`*Best for:`}</strong>{` Photographers & Designers`}</p>
    <h2>{`SuperPNG`}</h2>
    <p><strong parentName="p">{`SuperPNG`}</strong>{` is a free Adobe Photoshop plug-in for using PNG ("ping") files. It has more comprehensive support for the format than Photoshop's included plug-in and provides control over alpha channel handling.`}</p>
    <img className="alignnone" alt="" src="/images/2014-03-04-free-photoshop-plugins/out_dialog.png" width="430" height="396" />
PNG is an open source file format that supports tight lossless compression. It can be read on any platform by most graphics applications and is fully supported by all modern web browsers. SuperPNG allows greater control over PNG images
    <p><strong parentName="p">{`*Publisher:`}</strong>{` `}<a href="http://www.fnordware.com/" target="_blank" shape="rect">{`fnord`}</a>{`
`}<strong parentName="p">{`*Best for:`}</strong>{` Designers`}</p>
    <p>{`If you're a regular user of the PNG format, you'll know that Photoshop can sometimes struggle a bit to render PNGs quickly. SuperPNG aims to fix this by offering more control over your PNG output, allowing for a balance between speed and file size, control over the alpha channel and meta data. SuperPNG's a handy tool for taking control of your image export, and is free!`}</p>
    <h2>{` Wire Worm`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-04-free-photoshop-plugins/47c18b6c-e920-4a34-819a-e55272f0757a.jpg",
        "alt": "Wire Worm"
      }}></img></p>
    <p>{`This little plugin will help you remove wires and other unwanted objects from your images. There are, of course, other tools to achieve this goal, e.g. the good old clone stamp or the healing brush, besides a number of dedicated plugins and applications (see below). However, Wire Worm comes with a set of features that I have not seen elsewhere:`}</p>
    <ul>
      <li parentName="ul">{`specific tool designed for easy selection of long thin objects, e.g. wires`}</li>
      <li parentName="ul">{`advanced color matching for seamless patches without color bleeding`}</li>
      <li parentName="ul">{`ability to finetune patch after placement`}</li>
      <li parentName="ul">{`unlimited undo/redo`}</li>
    </ul>
    <p>{`Wire Worm is a free plugin with a single, simple purpose that may save you a lot of time removing telephone wires, fences and similar obstructions in your images. The interface is simple, allowing you to select an area around a wire or other obstruction and have the tool automatically remove it. Results are surprisingly good, especially for the price!`}</p>
    <p><strong parentName="p">{`*Publisher:`}</strong>{` `}<a parentName="p" {...{
        "href": "http://www.vicanek.de/"
      }}>{`MV's Plugins`}</a>{`
`}<strong parentName="p">{`*Best for:`}</strong>{` Photographers`}</p>
    <h2>{`Tych Panel 2`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-04-free-photoshop-plugins/wedding-photographer-goteborg-alex-and-alex.jpg",
        "alt": "Tych Panel 2"
      }}></img></p>
    <p>{`Photoshop plugins: Create diptych, triptych and ntych panels with Tych Panel 2. Image credit: Gordon Andersson - `}<a parentName="p" {...{
        "href": "http://www.gordonandersson.com"
      }}>{`http://www.gordonandersson.com`}</a></p>
    <p><strong parentName="p">{`*Publisher:`}</strong>{` `}<a parentName="p" {...{
        "href": "http://lumens.se/"
      }}>{`Lumens`}</a>{`
`}<strong parentName="p">{`*Good for:`}</strong>{` Photographers and designers`}</p>
    <p>{`This straightforward plugin makes it simple to create diptych (double), triptych (triple) and ntych (quadrule+) panels within Photoshop. Choose the alignment, number of rows and columns, or hybrid layouts, and Tych Panel 2 will create the layout for you utilizing Smart Objects and layer masks to avoid destructive editing.`}</p>
    <h2>{` GuideGuide`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-04-free-photoshop-plugins/demo.gif",
        "alt": " GuideGuide"
      }}></img></p>
    <p>{`With GuideGuide, pixel accurate columns, rows, midpoints, and baselines can be created based on your `}<strong parentName="p">{`document`}</strong>{` or `}<strong parentName="p">{`selection`}</strong>{` with the click of a button. Frequently used guide sets can be saved for repeat use. Grids can use multiple types of measurements.`}</p>
    <p><strong parentName="p">{`*Publisher:`}</strong>{` `}<a parentName="p" {...{
        "href": "http://www.cameronmcefee.com/"
      }}>{`Cameron McEfe`}</a>{`
`}<strong parentName="p">{`*Good for:`}</strong>{` Print & web designers`}</p>
    <p>{`This simple Photoshop plugin makes it easy to create a grid system in your Photoshop document, hijacking the built-in guides system to create a pinpoint accurate grid according to your chosen settings. You can use negative margins for special hanging columns, separately define individual margins and gutters, and save your preferred options for quick access in the future. Perfect for anyone using a grid to help them create their layouts.`}</p>
    <h2>{`Cut & Slice me`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-04-free-photoshop-plugins/w640-1.jpg",
        "alt": "Cut & Slice me"
      }}></img></p>
    <p><inlineCode parentName="p">{`Cut & Slice me`}</inlineCode>{` cuts and exports them in png format trimming the pixels you don't need, or let you can specify the size you want. Or you can export all states of your buttons. Cut`}{`&`}{`Slice Me makes it easy to export your designs to the web`}</p>
    <p><strong parentName="p">{`*Publisher:`}</strong>{` `}<a parentName="p" {...{
        "href": "http://www.peruho.com/"
      }}>{`Daniel Peruho`}</a>{`
`}<strong parentName="p">{`*Good for:`}</strong>{` Web designers`}</p>
    <p>{`This super-handy CS6-only plugin makes it easy to export your designs from Photoshop to the web. Rather than rely on Photoshop's outdated export for web functionality, Cut`}{`&`}{`Slice me offers a new way of doing things; allowing overlapping slices, multiple button states and even exporting different resolutions of the same assets to cater for different screen sizes and devices.`}</p>
    <h2>{`Mr. Stacks`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-04-free-photoshop-plugins/mrstacks.jpg",
        "alt": "Mr. Stacks"
      }}></img></p>
    <p>{`Mr. Stacks  is a Photoshop script that rapidly generates storyboards, stacks, and PDF(s) for CD check-ins, client-ish presentations, and whatever else it is you do. Helping to Nail some of the most monotonous tasks in art direction.`}</p>
    <p><strong parentName="p">{`*Publisher:`}</strong>{` `}<a parentName="p" {...{
        "href": "http://hai@MrStacks.com"
      }}>{`Mr. Stacks`}</a>{`
`}<strong parentName="p">{`*Good for:`}</strong>{` Photographers, designers, film-makers`}</p>
    <p>{`This Photoshop script converts layer comps into a storyboard view, compositing your individual comps into a layout that can be easily output as PDF for printing and sharing with others.`}</p>
    <h2>{`Freeware Boundary Noise Reduction`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-04-free-photoshop-plugins/stringTop-original.jpg",
        "alt": "Freeware Boundary Noise Reduction"
      }}></img></p>
    <p>{`The freeware version of Boundary Noise Reduction lets you remove noise from dSLR images and film scans. Many users comment that it's a big improvement over the noise reduction tool built into Photoshop.`}</p>
    <p><strong parentName="p">{`*Publisher:`}</strong>{` Colormancer
`}<strong parentName="p">{`*Good for:`}</strong>{` Photographers`}</p>
    <p>{`This Photoshop plugin is a freeware version of the full Boundary Noise Reduction plugin from Colormancer. Offering a simplified set of user controls when compared to the pro version, it's superior to Photoshop's own noise reduction filter.`}</p>
    <h2>{`Pano Warp`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-04-free-photoshop-plugins/panowarp.jpg",
        "alt": "Pano Warp"
      }}></img></p>
    <p>{`The Pano Warp plugin will give a simple facility to compensate barrel distortion and vignetting.`}</p>
    <p><strong parentName="p">{`*Publisher:`}</strong>{` `}<a parentName="p" {...{
        "href": "http://vicanek.de/"
      }}>{`MV's Plugins`}</a>{`
`}<strong parentName="p">{`*Good for:`}</strong>{` Print and web designers, photographers`}</p>
    <p>{`While Photoshop offers a handy Lens Correction feature, this filter takes an entirely different approach. Use this Photoshop plugin to adjust the focal length, roll and horizon height to correct for panorama distortion easily.`}</p>
    <h2>{`NKS5 Natural Media Toolkit`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-04-free-photoshop-plugins/NKS-Natural-Media-toolkit-for-photoshop.jpg",
        "alt": "NKS5 Natural Media Toolkit"
      }}></img></p>
    <p>{`Nkurence's tool lets you quickly access real-media preset effects`}</p>
    <p><strong parentName="p">{`*Publisher:`}</strong>{` `}<a parentName="p" {...{
        "href": "http://Nkurence.com"
      }}>{`Nkurence`}</a>{`
`}<strong parentName="p">{`*Good for:`}</strong>{` designers & artists`}</p>
    <p>{`NKS5 is a custom toolkit for Adobe Photoshop CS5, CS5.5 and CS6 – it provides a wide range of natural media, texturing and production tools in an attractive, easy to use palette with a minimal footprint. It’s a free download, but if you’re pro and it’s helping or if you just want to see more projects like this come about please consider making a donation below. Donators will receive download links to updates as the’re available along with early access to future extensions.`}</p>
    <h2>{`Sinedots II`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-04-free-photoshop-plugins/Sinedots2.jpg",
        "alt": "Sinedots II"
      }}></img></p>
    <p>{`Generate abstract, random wave patterns with Sinedots II`}</p>
    <p><strong parentName="p">{`*Publisher:`}</strong>{` `}<a parentName="p" {...{
        "href": "http://www.philipp-spoeth.de/"
      }}>{`Philipp Spoeth`}</a>{`
`}<strong parentName="p">{`*Good for:`}</strong>{` Designers`}</p>
    <p>{`If you're looking for a quick way to generate abstract, random wave patterns for use as background art, this Photoshop plugin could be just what you need. It's phenomenally complex, and it’s surprisingly old - having been around for at least a decade - but still does the trick and creates some amazing patterns.`}</p>
    <h2>{`Web Font Plugin`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-04-free-photoshop-plugins/w640.jpg",
        "alt": "Web Font Plugin"
      }}></img></p>
    <p>{`Web Font Plugin lets you use Google Web Fonts inside Photoshop.`}</p>
    <p><strong parentName="p">{`*Publisher:`}</strong>{` `}<a parentName="p" {...{
        "href": "http://www.extensis.com/"
      }}>{`Extensis`}</a>{`
`}<strong parentName="p">{`*Good for:`}</strong>{` Web designers`}</p>
    <p>{`Easy access to use thousands of WebINK web fonts in Photoshop from foundries like Dalton Maag, Mark Simonson, MoreType, OurType, TypeTogether and ParaType.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      